@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap);


@font-face {
	font-family: 'tt-commons-regular';
	src: local('tt-commons-regular'),
		url(/static/media/tt-commons-regular-WO.4860ddbc.woff) format('woff');
	font-style: normal;
	font-weight: 500;
}

html {
	height: 100%;
	scroll-behavior: smooth;
}

body {
	margin: 0;
	padding: 0;
	height: 100%;
	font-family: 'Roboto', sans-serif;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	line-height: normal;
	box-sizing: border-box;
}

#root {
	height: 100%;
}

